// for web components
import '@fil-global/gds-components/scripts/polyfills/ie11';
// for Django CMS
import './ie11';
/* core-js */
import 'core-js/stable/array';
import 'core-js/stable/object/assign';
import 'core-js/stable/object/entries';
import 'core-js/stable/dom-collections';
import 'core-js/stable/promise';
import 'core-js/stable/string';
import 'core-js/stable/symbol/async-iterator';

//in order to fix the GDS3 duplicated definition conflicts.
if( CustomElementRegistry ) {
  CustomElementRegistry.prototype.define = (function (oldWebComponentDefineFunc) {
    return function( name /*,constructor, options*/ ){
      if (!this.get(name)) {
        oldWebComponentDefineFunc.apply(this, arguments);
      }
    };
  })(CustomElementRegistry.prototype.define);
}

/* regenrator runtime */
import 'regenerator-runtime/runtime';
